import { Component, Input, OnChanges } from '@angular/core';

import { Alert } from '@Interfaces/Alert';
import { Group } from '@Interfaces/Group';
import { Swimmer } from '@Interfaces/Swimmer';
import { TranslateService } from '@ngx-translate/core';
import { FluxService } from './flux.service';

import * as moment from 'moment';

@Component({
    selector: 'app-flux',
    templateUrl: './flux.component.html'
})
export class FluxComponent implements OnChanges {

    public alert: Alert = new Alert({ disableIcon: true });
    public flux: any[] = [];
    public loadMoreBtn = false;
    public loading = false;
    public today = moment().format();
    private pagination = 5;

    @Input() group: Group;
    @Input() swimmer: Swimmer;

    constructor(
        private fluxService: FluxService,
        private translate: TranslateService
    ) {
        moment.locale(translate.currentLang || 'fr');
    }

    private initParams(loadMore: boolean): any {
        const params: any = {};

        if (this.swimmer) {
            params.swimmerID = this.swimmer._id;
        }
        if (loadMore) {
            const lastElem = this.flux[this.flux.length - 1];
            if (lastElem) {
            params.date = lastElem.date || lastElem.createdAt;
            }
        }
        return params;
    }

    updateFlux(loadMore: boolean = false) {
        this.loading = true;
        this.fluxService
            .get(this.group._id, this.initParams(loadMore))
            .then(fluxElems => {
                const flux = fluxElems.elems.map((elem: any) => {
                    if (this.swimmer) {
                        const index = elem.swimmers.findIndex((swms: any) => swms.swimmerID._id === this.swimmer._id);
                        elem.showAbsence = index >= 0 && !elem.swimmers[index].isPresent;
                    }
                    if (elem.version) {
                        elem.done = this.today > elem.createdAt;
                    } else {
                        elem.done = this.today > elem.date;
                    }
                    const dateRef = elem.title ? elem.date : elem.createdAt;
                    elem.timeAgo = moment(dateRef).format('ddd DD MMM HH:mm');
                    return elem;
                });
                this.loadMoreBtn = flux.length >= this.pagination || !fluxElems.done;
                this.flux = this.flux.concat(flux);
                this.loading = false;
            })
            .catch((err: any) => {
                this.loading = false;
                this.alert.setError(err);
            });
    }

    ngOnChanges(changes: any): void {
        if (changes.group) {
            this.flux = [];
            this.loadMoreBtn = false;
            this.group = changes.group.currentValue;
            this.updateFlux();
        }
    }

    closeErr() { this.alert.reset(); }

}
