import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { serviceHandler } from '@Helpers/handler';

@Injectable()
export class FluxService {

    private fluxUrl = '/api/flux';

    constructor(private http: HttpClient) { }

    get(idGroup: string, swimmerQuery: any = {}): Promise<any> {
        return this.http
            .get(`${this.fluxUrl}/${idGroup}`, { params: swimmerQuery })
            .toPromise()
            .then((response: any) => response)
            .catch(serviceHandler);
    }

}
