import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-switcher',
    templateUrl: './switcher.component.html'
})
export class SwitcherComponent {

    @Input() groups: any;
    @Input() index: number;
    @Output() newGroupIndex: EventEmitter<number> = new EventEmitter<number>();

    constructor() { }

    changeGroup(index: number): void {
        this.newGroupIndex.emit(index);
    }

}
