import { ErrorHandler, Injectable } from '@angular/core';
import { CoreService } from '@App/Core/core.service';
import * as Sentry from '@sentry/browser';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(private core: CoreService) { }

    groupError(error) {
        if (this.core.env !== 'development') {
            (window as any).FS.log('error', error);
        }
        console.group('[Global Error Handler] - call Sentry');
        console.error(error);
        console.groupEnd();
    }

    handleError(error) {
        this.groupError(error);
        if (this.core.env === 'development') return;

        if (this.core.user) {
            Sentry.configureScope(scope => {
                scope.setUser({
                    id: this.core.user._id,
                    email: this.core.user.email,
                    environment: this.core.env,
                    role: this.core.user.role,
                    FSsession: (window as any).FS.getCurrentSessionURL()
                });
            });
        }

        Sentry.captureException(error.originalError || error);
    }
}
