<div class="headerbar hidden-xs hidden-print">
    <div class="container">
        <div class="ui secondary menu" style="padding:8px;">
            <a [routerLink]="['/hello']">
                <img id="myElement" src="assets/img/Padlie-logo--default.svg"
                     height="50px" class="tippy" title="...">
            </a>
            <div class="right menu">

                <!-- HOME -->
                <a [routerLink]="['/hello']" [routerLinkActive]="['active']"
                   class="item icon">
                    <i class="icon home"></i>
                </a>

                <!-- HOME -->
                <a *ngIf="user.swimmer"
                   [routerLink]="['/', 'swimmer', user.swimmer._id]"
                   [routerLinkActive]="['active']" class="item icon">
                    {{ 'HEADERBAR.PROFILE' | translate }}
                </a>

                <!-- + WORKOUT -->
                <a *ngIf="user.coach" class="item"
                   [routerLink]="['/workout/edit/new']"
                   [routerLinkActive]="['active']">
                    <i
                       class="icon add circle"></i>{{ 'HEADERBAR.WORKOUT' | translate }}
                </a>

                <!-- + WORKOUT -->
                <a class="item" [routerLink]="['/calendar']"
                   [routerLinkActive]="['active']">
                    Calendrier</a>

                <!-- DROPDOWN -->
                <div class="dropdown dropdown-user">
                    <button class="ui image label basic" data-toggle="dropdown">
                        <app-avatar [user]="user" [img]="img"></app-avatar>
                        <span>{{ buttonName }}</span>
                        <i class="bars icon"></i>
                    </button>

                    <!--  LINKS -->
                    <ul class="dropdown-menu">

                        <!-- PREFERENCES -->
                        <li>
                            <a [routerLink]="['/settings/informations']">
                                <i class="settings icon"></i>
                                {{ 'HEADERBAR.SETTINGS' | translate }}
                            </a>
                        </li>

                        <!-- BIBLIOTHEQUE -->
                        <li *ngIf="user.coach">
                            <a [routerLink]="['/', 'library']">
                                <i class="bookmark icon"></i>
                                {{ 'HEADERBAR.LIBRARY' | translate }}
                            </a>
                        </li>
                        <li role="separator" class="divider"></li>
                        <li>
                            <a href="http://bit.ly/2zhKCWK" target="_blank">
                                <i class="facebook square icon blue"></i>
                                {{ 'HEADERBAR.CONTACT' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="https://www.padlie.com/fr/support"
                               target="_blank">
                                <i class="help circle icon grey"></i>
                                {{ 'HEADERBAR.HELP' | translate }}
                            </a>
                        </li>

                        <!-- LOGOUT -->
                        <li>
                            <a (click)="logout()">
                                <i class="shutdown icon red"></i>
                                {{ 'HEADERBAR.LOGOUT' | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-default visible-xs hidden-print">
    <div class="container">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed"
                    data-toggle="collapse" data-target="#menu"
                    aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" [routerLink]="['/hello']"
               style="margin-left:15px;">
                <img src="assets/img/Padlie-logo--default.svg" height="50px">
            </a>
        </div>

        <!--  MODE iPhone-->
        <div class="collapse navbar-collapse" id="menu"
             style="padding-top:0px;">
            <ul class="nav navbar-nav navbar-right visible-xs">

                <!-- HOME -->
                <li>
                    <a [routerLink]="['/hello']" [routerLinkActive]="['active']"
                       class="item" (click)="onLinkClik()">
                        {{ 'HEADERBAR.HOME' | translate }}
                    </a>
                </li>
                <li *ngIf="user.swimmer">
                    <a [routerLink]="['/', 'swimmer', user.swimmer._id]"
                       (click)="onLinkClik()">
                        {{ 'HEADERBAR.PROFILE' | translate }}
                    </a>
                </li>

                <!-- + WORKOUT -->
                <li *ngIf="user.coach">
                    <a [routerLink]="['/workout', 'edit', 'new']"
                       [routerLinkActive]="['active']" class="item"
                       (click)="onLinkClik()">
                        + {{ 'HEADERBAR.WORKOUT' | translate }}
                    </a>
                </li>

                <!-- + CALENDAR -->
                <li>
                    <a [routerLink]="['/calendar']"
                       [routerLinkActive]="['active']" class="item"
                       (click)="onLinkClik()">
                        Calendrier
                    </a>
                </li>

                <!-- LIBRARY -->
                <li *ngIf="user.coach">
                    <a [routerLink]="['/', 'library']"
                       [routerLinkActive]="['active']" class="item"
                       (click)="onLinkClik()">
                        {{ 'HEADERBAR.LIBRARY' | translate }}
                    </a>
                </li>

                <!-- PREFERENCES -->
                <li>
                    <a [routerLink]="['/settings/informations']"
                       [routerLinkActive]="['active']" (click)="onLinkClik()">
                        {{ 'HEADERBAR.SETTINGS' | translate }}
                    </a>
                </li>

                <!-- LOGOUT -->
                <li>
                    <a (click)="logout()" (click)="onLinkClik()">
                        {{ 'HEADERBAR.LOGOUT' | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>