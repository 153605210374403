import { Component, Input, OnChanges } from '@angular/core';

import { HomeService } from '@App/Home/home.service';
import { Alert } from '@Interfaces/Alert';
import { Group } from '@Interfaces/Group';
import { Limiter } from '@Interfaces/Limiter';
import { Presence } from '@Interfaces/Swimmer';

@Component({
    selector: 'app-presence',
    templateUrl: './presence.component.html'
})
export class PresenceComponent implements OnChanges {

    public alert: Alert = new Alert({ disableIcon: true });
    public loading = false;
    public presence: Presence[] = [];
    public limiter: Limiter;

    @Input() group: Group;
    @Input() index: number;

    constructor(private homeService: HomeService) { }

    realStart() {
        this.limiter = new Limiter(5);
        if (!this.group.swimmers.length) return;
        this.homeService
            .getGroupPresence(this.group._id)
            .then(presence => {
                this.limiter.max = presence.length;
                this.presence = presence;
            })
            .catch(err => {
                this.loading = false;
                this.alert.setError(err);
            });
    }

    ngOnChanges(changes: any): void {
        if (changes.group) {
            this.group = changes.group.currentValue;
            this.realStart();
        }
    }

    closeErr() { this.alert.reset(); }

}
