<div [id]="idAlert" *ngIf="!alert.options.compactMode"
     class="ui icon message tiny {{settings.color}} mb-sm animated fadeIn">
    <i *ngIf="!alert.options.disableIcon" class="{{settings.icons}} icon"></i>
    <i *ngIf="!alert.options.hideClose" class="close icon"
       (click)="closeAction()"></i>
    <div class="content">
        <div class="header">
            {{ alert.title | translate }}
        </div>
        <ul class="list" *ngIf="alert.messages">
            <li *ngFor="let message of alert.messages" [@fadeInOut]="'in'">
                {{ message | translate }}
            </li>
        </ul>
        <ng-content *ngIf="alert.options.content"></ng-content>
    </div>
</div>

<div [id]="idAlert" *ngIf="alert.options.compactMode"
     class="ui compact message {{settings.color}} col-sm-12">
    <p *ngFor="let message of alert.messages" [@fadeInOutCompact]="'in'">
        <i class="{{settings.icons}} icon"></i>
        {{ alert.message | translate }}
    </p>
</div>