<div class="col-sm-12 mb-md">
    <h4 class="title-separator">
        {{ 'HOME.SWIMMERS.TITLE' | translate:{ value: group.swimmers.length } }}
    </h4>
    <div class="ui middle aligned selection list">
        <ng-container *ngFor="let swimmer of group.swimmers; let i = index">
            <a *ngIf="i < limiter.limit" (click)="redirect(swimmer)"
               class="item">
                <app-avatar [user]="swimmer._user"
                            imgClass="mini rounded margin-right"></app-avatar>
                <div class="content inline-block">
                    <div class="header">
                        <i *ngIf="!swimmer.joined" class="wait icon"></i>
                        <i *ngIf="swimmer.joined" class="check icon green"></i>
                        {{swimmer._user.firstName}} {{swimmer._user.lastName}}
                    </div>
                </div>
            </a>
        </ng-container>
    </div>
    <div *ngIf="group.swimmers && !group.swimmers.length" class="text-center">
        <a class="ui button fluid"
           [routerLink]="['/', 'settings', 'informations']"
           [queryParams]="{ group: index}">
            {{ 'HOME.SWIMMERS.ADD-SWIMMER' | translate }}
        </a>
    </div>
    <app-limiter [src]="limiter"></app-limiter>
</div>