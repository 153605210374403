/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./avatar.component";
var styles_AvatarComponent = [];
var RenderType_AvatarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
function View_AvatarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [], [[8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.loadedAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { loaded: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(2, "", _co.baseClass, " ", _co.imgClass, ""); var currVal_2 = _ck(_v, 3, 0, _co.loaded); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgDisplay; _ck(_v, 0, 0, currVal_0); }); }
function View_AvatarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [["src", "assets/img/male.png"]], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.loadedAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { loaded: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "", _co.baseClass, " ", _co.imgClass, ""); var currVal_1 = _ck(_v, 3, 0, _co.loaded); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AvatarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [["src", "assets/img/female.png"]], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.loadedAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { loaded: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "", _co.baseClass, " ", _co.imgClass, ""); var currVal_1 = _ck(_v, 3, 0, _co.loaded); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AvatarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [["src", "assets/img/every-user.jpg"]], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.loadedAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { loaded: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "", _co.baseClass, " ", _co.imgClass, ""); var currVal_1 = _ck(_v, 3, 0, _co.loaded); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AvatarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgDisplay; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.imgDisplay && (_co.user.gender === "male")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.imgDisplay && (_co.user.gender === "female")); _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.imgDisplay && !_co.user.gender); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AvatarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i0.ɵdid(1, 573440, null, 0, i2.AvatarComponent, [], null, null)], null, null); }
var AvatarComponentNgFactory = i0.ɵccf("app-avatar", i2.AvatarComponent, View_AvatarComponent_Host_0, { user: "user", img: "img", imgClass: "imgClass" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
