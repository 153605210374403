/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../avatar/avatar.component.ngfactory";
import * as i2 from "../avatar/avatar.component";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./swimmers-list.component";
var styles_SwimmersListComponent = [];
var RenderType_SwimmersListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwimmersListComponent, data: {} });
export { RenderType_SwimmersListComponent as RenderType_SwimmersListComponent };
function View_SwimmersListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-avatar", [["imgClass", "ui avatar image"]], null, null, null, i1.View_AvatarComponent_0, i1.RenderType_AvatarComponent)), i0.ɵdid(1, 573440, null, 0, i2.AvatarComponent, [], { user: [0, "user"], imgClass: [1, "imgClass"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.swimmerID._user; var currVal_1 = "ui avatar image"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SwimmersListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.index < _co.limit.min) || ((_v.context.index < _co.countPhoto) && (_v.context.index < _co.limit.max))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SwimmersListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", "SHARED.SWIMMER-LIST.MORE"]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i0.ɵpod(2, { value: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "SHARED.SWIMMER-LIST.MORE"; var currVal_1 = _ck(_v, 2, 0, _co.others); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SwimmersListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.swimmers; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.others > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SwimmersListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shared-swimmers-list", [], null, null, null, View_SwimmersListComponent_0, RenderType_SwimmersListComponent)), i0.ɵdid(1, 114688, null, 0, i5.SwimmersListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SwimmersListComponentNgFactory = i0.ɵccf("shared-swimmers-list", i5.SwimmersListComponent, View_SwimmersListComponent_Host_0, { swimmers: "swimmers" }, {}, []);
export { SwimmersListComponentNgFactory as SwimmersListComponentNgFactory };
