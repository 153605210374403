import { Component, Input, OnChanges } from '@angular/core';

import { User } from '@Interfaces/User';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnChanges {

    public baseClass = 'ui image img-loader';
    public imgDisplay: string = null;
    public loaded = false;

    @Input() user: User;
    @Input() img: string;
    @Input() imgClass: string = null;

    constructor() { }

    ngOnChanges(changes: any): void {
        if (changes.user || changes.img) {
            if (this.img) {
                this.imgDisplay = this.img;
            } else if (this.user && this.user.profile_picture) {
                this.imgDisplay = this.user.profile_picture;
            }
        }
    }

    loadedAction() {
        this.loaded = true;
    }
}
