<div class="col-sm-12 mb-md">
    <h4 class="title-separator">{{ 'HOME.SWITCH' | translate }}</h4>

    <div *ngIf="groups.length > 2" class="ui fluid vertical menu">
        <a *ngFor="let group of groups; let i = index" class="item"
           (click)="changeGroup(i)" [ngClass]="{'active': i === index}">
            <a class="ui empty circular label"
               [style.background-color]="group.color"></a>
            {{group.name}}
        </a>
    </div>

    <div *ngIf="groups.length <= 2" class="three ui basic buttons large">
        <button *ngFor="let group of groups; let i = index"
                (click)="changeGroup(i)" class="ui button inner-sp-xs"
                [ngClass]="{'active': i === index}">
            <a class="ui empty circular label"
               [style.background-color]="group.color"></a>
            {{group.name}}
        </button>
    </div>
</div>