import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SuiModule } from 'ng2-semantic-ui';
import { ShareButtonsModule } from 'ngx-sharebuttons';

import { AlertComponent } from './alert/alert.component';
import { AvatarComponent } from './avatar/avatar.component';
import { LimiterComponent } from './limiter/limiter.component';
import { SwimmersListComponent } from './swimmers-list/swimmers-list.component';

import { DatePickerComponent } from './date-picker/date-picker.component';
import { FluxComponent } from './flux/flux.component';
import { FluxService } from './flux/flux.service';
import { SwimmerWhitoutGroupComponent } from './flux/swimmer-whitout-group/swimmer-whitout-group.component';
import { WelcomeMessageComponent } from './flux/welcome-message/welcome-message.component';
import { HourPickerComponent } from './hour-picker/hour-picker.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        SuiModule,
        ShareButtonsModule.forRoot()
    ],
    declarations: [
        AlertComponent,
        AvatarComponent,
        SwimmersListComponent,
        LimiterComponent,
        FluxComponent,
        WelcomeMessageComponent,
        SwimmerWhitoutGroupComponent,
        DatePickerComponent,
        HourPickerComponent
    ],
    exports: [
        AlertComponent,
        AvatarComponent,
        TranslateModule,
        SwimmersListComponent,
        LimiterComponent,
        FluxComponent,
        WelcomeMessageComponent,
        SwimmerWhitoutGroupComponent,
        DatePickerComponent,
        HourPickerComponent
    ],
    providers: [FluxService]
})
export class SharedModule { }
