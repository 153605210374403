<div class="col-sm-12 mb-md">
    <h4 class="title-separator">
        {{ 'HOME.TODAY.TITLE' | translate }}
        <span *ngIf="!ativities.length && !loading && isCoach">,
            Repos
        </span>
        <i *ngIf="loading" class="fitted asterisk blue loading icon"></i>
    </h4>

    <!-- ALERT -->
    <app-alert *ngIf="alert.type" (close)="closeErr()" [alert]="alert"
               [update]="alert.update"></app-alert>

    <div *ngIf="!ativities.length && !loading">
        <div class="text-center">
            <a class="ui button fluid" *ngIf="isCoach"
               [routerLink]="['/', 'workout', 'edit', 'new']"
               [queryParams]="{ group: group._id}">
                {{ 'HOME.TODAY.ADD-WORKOUT' | translate }}
            </a>
        </div>
        <h3 class="ui header fluid text-center" *ngIf="!isCoach">
            {{ 'HOME.TODAY.REST' | translate }}
        </h3>
    </div>
    <span *ngFor="let item of ativities; let i = index">
        <a *ngIf="isCoach || (!isCoach && !item.block)"
           [routerLink]="['/', 'workout', item._id]">
            <div *ngIf="i < limiter.limit" class="ui card mt-sm">
                <div class="content">
                    <div class="header">
                        <a class="ui blue empty circular label"></a>
                        {{ item.title }}
                    </div>
                    <div class="meta">
                        <span>{{ item.date | date:'HH:mm' }}</span>
                        <div class="ui label clear pull-right">
                            <i class="flag checkered icon"></i>
                            {{ item.distance/1000 }} km
                        </div>
                    </div>
                </div>
            </div>
        </a>
        <div *ngIf="!isCoach && item.block">
            <div *ngIf="i < limiter.limit" class="ui card mt-sm">
                <div class="content">
                    <div class="header">
                        <i class="lock icon"></i>
                        {{ item.title }}
                    </div>
                    <div class="meta">
                        <span>{{ item.date | date:'HH:mm' }}</span>
                        <div class="ui label clear pull-right">
                            <i class="flag checkered icon"></i>
                            ?? km
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
    <app-limiter [src]="limiter"></app-limiter>
</div>