<div class="col-sm-12 mb-md">
    <h4 class="title-separator">
        {{ 'HOME.PRESENCE.TITLE' | translate }}
        <i *ngIf="loading" class="fitted asterisk blue loading icon"></i>
    </h4>

    <!-- ALERT -->
    <app-alert *ngIf="alert.type" (close)="closeErr()" [alert]="alert"
               [update]="alert.update"></app-alert>

    <div class="ui ordered list"
         *ngIf="group.swimmers && group.swimmers.length">
        <ng-container *ngFor="let swimmer of presence; let i = index">
            <div *ngIf="i < limiter.limit" class="item">
                <app-avatar [user]="swimmer._user" class="pull-left"
                            imgClass="mini rounded margin-right"></app-avatar>
                <div class="content">
                    <div class="header">
                        {{swimmer._user.firstName}} {{swimmer._user.lastName}}
                        <span *ngIf="swimmer.presence === 100">🥇</span>
                    </div>
                    <span
                          class="thin-grey-text">{{swimmer.presence | number:'1.0-0'}}
                        %</span>
                    <span class="thin-grey-text">
                        {{ 'HOME.PRESENCE.WORKOUTS' | translate:{ value: swimmer.workouts } }}
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="group.swimmers && !group.swimmers.length" class="text-center">
        <a class="ui button fluid"
           [routerLink]="['/', 'settings', 'informations']"
           [queryParams]="{ group: index}">
            {{ 'HOME.SWIMMERS.ADD-SWIMMER' | translate }}
        </a>
    </div>
    <p *ngIf="group.swimmers && group.swimmers.length" class="thin-grey-text">
        <i>{{ 'HOME.PRESENCE.LEGEND' | translate }}</i>
    </p>
    <app-limiter [src]="limiter"></app-limiter>
</div>