var Limiter = /** @class */ (function () {
    function Limiter(limit, options) {
        this.limit = 1;
        this.show = false;
        this.text = 'BTN.SEE-MORE';
        if (options) {
            Object.assign(this, options);
            if (options.max)
                this.show = options.max > options.min;
        }
        this.limit = limit;
        this.min = this.limit;
    }
    Object.defineProperty(Limiter.prototype, "max", {
        get: function () { return this._max; },
        set: function (newMax) {
            this._max = newMax;
            this.show = this._max > this.min;
        },
        enumerable: true,
        configurable: true
    });
    Limiter.prototype.tooggle = function () {
        if (this.limit === this.min) {
            this.limit = this._max;
            this.text = 'BTN.SEE-LESS';
        }
        else {
            this.limit = this.min;
            this.text = 'BTN.SEE-MORE';
        }
    };
    return Limiter;
}());
export { Limiter };
