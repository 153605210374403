<div class="container home-page">

    <app-alert *ngIf="alert.type" (close)="closeErr()" [alert]="alert"
               [update]="alert.update"></app-alert>

    <h1 class="hero animated fadeInDown">
        {{ 'HOME.TITLE' | translate:user }}
    </h1>

    <div class="row">
        <div class="col-sm-8">
            <div class="col-sm-12">
                <app-flux *ngIf="groupActive && !isSwimmer"
                          [group]="groupActive"></app-flux>
                <app-flux *ngIf="groupActive && isSwimmer" [group]="groupActive"
                          [swimmer]="user.swimmer"></app-flux>
                <app-welcome-message
                                     *ngIf="!groupActive && user.role === 'coach'">
                </app-welcome-message>
                <div class="row">
                    <app-swimmer-whitout-group
                                               *ngIf="!groupActive && user.role === 'swimmer'">
                    </app-swimmer-whitout-group>
                </div>
            </div>
        </div>

        <!-- SideBar Right -->
        <div class="col-sm-4 sticky">
            <div class="row">
                <app-switcher *ngIf="groups.length > 1" [groups]="groups"
                              [index]="groupIndex"
                              (newGroupIndex)="changeGroup($event)">
                </app-switcher>
                <app-today *ngIf="groupActive" [group]="groupActive">
                </app-today>
                <app-swimmers-list *ngIf="groupActive" [group]="groupActive"
                                   [index]="groupIndex"></app-swimmers-list>
                <app-goals *ngIf="groupActive" [group]="groupActive">
                </app-goals>
                <app-presence *ngIf="groupActive" [group]="groupActive"
                              [index]="groupIndex"></app-presence>
            </div>
        </div>
    </div>

</div>