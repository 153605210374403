<div class="ui segment very padded animated fadeInUp">
    <h1 class="ui header">{{ 'SWIMMER.EMPTY-GRP.TITLE' | translate }}</h1>
    <p>{{ 'SWIMMER.EMPTY-GRP.CONTENT' | translate }}</p>
    <p>
        <button shareButton="facebook" [sbUrl]="'https://app.padlie.com'"
                class="ui facebook button">
            <i class="facebook icon"></i>
            {{ 'BTN.SHARE' | translate }}
        </button>
        <button shareButton="twitter" [sbUrl]="'https://app.padlie.com'"
                class="ui twitter button">
            <i class="twitter icon"></i>
            Twitter
        </button>
    </p>
    <h4 class="ui header">
        <img src="/assets/img/Padlie-logo-minimal--default.png">
        <div class="content">
            The Padlie Team
            <div class="sub header">
                <i>In hard work we trust</i>
            </div>
        </div>
    </h4>
</div>