import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { randomNumber } from '@Helpers/basic';
import { smoothScroll } from '@Helpers/smoothScroll';
import { Alert } from '@Interfaces/Alert';

const AlertSettings = {
    Error: {
        color: 'red',
        icons: 'warning sign'
    },
    Success: {
        color: 'green',
        icons: 'checkmark'
    }
};

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300)
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 }))
            ])
        ]),
        trigger('fadeInOutCompact', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            transition(':leave', [
                animate(0, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AlertComponent implements OnChanges {

    public msgIcon: string = null;
    public defaultTitle: string = null;
    public settings: any;
    public idAlert: string;

    @Input() alert: Alert;
    @Input() update: number;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(private translateService: TranslateService) { }

    private updateAlert() {
        this.idAlert = `alert-${randomNumber()}`;

        if (!AlertSettings[this.alert.type]) {
            throw new Error('You must define a Type');
        }
        if (this.alert.options.autoScroll) {
            smoothScroll(`#${this.idAlert}`, this.alert.options.container);
        }
        this.settings = AlertSettings[this.alert.type];
    }

    ngOnChanges(changes: any): void {
        if (changes.update) {
            this.updateAlert();
        }
    }

    closeAction() { this.close.emit(); }
}
