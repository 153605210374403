import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CoreService } from '@App/Core/core.service';
import { HomeService } from '@App/Home/home.service';

import { Alert } from '@Interfaces/Alert';
import { Group } from '@Interfaces/Group';
import { User } from '@Interfaces/User';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {

    public alert: Alert = new Alert({ disableIcon: true });
    public groupActive: Group;
    public groups: Group[] = [];
    public user: User;
    public groupIndex = -1;
    public isSwimmer: boolean;

    constructor(
        private homeService: HomeService,
        private core: CoreService,
        private title: Title
    ) {
        this.user = this.core.user;
        this.isSwimmer = !!this.user.swimmer;
        this.title.setTitle(`Hello ${this.user.firstName}`);
    }

    ngOnInit(): void {
        const initWitGroup = (groupActive: Group) => {
            this.groupActive = this.core.groupActive;
            this.groupIndex = this.core.groupIndex;
            if (this.core.groups) this.groups = this.core.groups;
            else {
                this.core.Groups$.subscribe((groups: Group[]) => {
                    this.groups = groups;
                });
            }
        };

        if (this.core.groupActive) initWitGroup(this.core.groupActive);
        else this.core.GroupActive$.subscribe(initWitGroup);
    }

    changeGroup(index: number): void {
        this.groupIndex = index;
        this.groupActive = this.groups[index];
        this.core.groupActive = this.groupActive;
        this.core.groupIndex = this.groupIndex;
    }

    closeErr() { this.alert.reset(); }
}
