import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { HomeService } from '@App/Home/home.service';
import { Alert } from '@Interfaces/Alert';
import { CompiledGoals } from '@Interfaces/Goal';
import { Group } from '@Interfaces/Group';
import { Limiter } from '@Interfaces/Limiter';

@Component({
    selector: 'app-goals',
    templateUrl: './goals.component.html'
})
export class GoalsComponent implements OnChanges {

    public alert: Alert = new Alert({ disableIcon: true });
    public loading = false;
    public goals: CompiledGoals[] = [];
    public limiter: Limiter;

    @Input() group: Group;

    constructor(private homeService: HomeService) { }

    realStart(): void {
        this.loading = true;
        this.limiter = new Limiter(4);
        this.homeService
            .getGroupGoals(this.group._id)
            .then(goals => {
                this.limiter.max = goals.length;
                this.goals = goals;
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.alert.setError(err);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.group) {
            this.group = changes.group.currentValue;
            this.realStart();
        }
    }

    closeErr() { this.alert.reset(); }

}
