<div class="col-sm-12 mb-md">
    <h4 class="title-separator">
        {{ 'HOME.GOALS.TITLE' | translate }}
        <i *ngIf="loading" class="fitted asterisk blue loading icon"></i>
    </h4>

    <!-- ALERT -->
    <app-alert *ngIf="alert.type" (close)="closeErr()" [alert]="alert"
               [update]="alert.update"></app-alert>

    <h4 *ngIf="!goals.length" class="text-center">
        {{ 'HOME.GOALS.EMPTY' | translate }}
    </h4>
    <span *ngIf="goals.length">
        <div *ngFor="let goal of goals; let i = index">
            <h3 *ngIf="i < limiter.limit" class="mt-sm">
                <span class="ui label pull-left small"
                      style="margin: 0px 10px 0 0">
                    <b>x{{goal.value}}</b>
                </span>
                {{goal.name}}
            </h3>
        </div>
        <app-limiter [src]="limiter"></app-limiter>
    </span>
</div>