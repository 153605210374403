import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { sortSwimmers } from '@Helpers/sort';
import { Group } from '@Interfaces/Group';
import { Limiter } from '@Interfaces/Limiter';

@Component({
    selector: 'app-swimmers-list',
    templateUrl: './swimmers-list.component.html'
})
export class SwimmersListComponent implements OnChanges {

    public limiter: Limiter;

    @Input() group: Group;
    @Input() index: number;

    constructor(private router: Router) { }

    updateList() {
        this.limiter = new Limiter(5);
        this.limiter.max = this.group.swimmers.length;
        this.group.swimmers.sort(sortSwimmers);
    }

    redirect(swimmer: any) {
        if (swimmer._group) {
            return this.router.navigate(['/', 'swimmer', swimmer._id]);
        }
    }

    ngOnChanges(changes: any): void {
        if (changes.group) {
            this.group = changes.group.currentValue;
            this.updateList();
        }
    }

}
