import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
Sentry.init({
    dsn: 'https://b9f444c465d140dfaecea5d4c20b0bd8@sentry.io/1225130',
    maxBreadcrumbs: 30
});
var ɵ0 = (createTranslateLoader);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
