import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { serviceHandler } from '@Helpers/handler';
import { CompiledGoals } from '@Interfaces/Goal';
import { Group } from '@Interfaces/Group';
import { Presence } from '@Interfaces/Swimmer';
import { Workout } from '@Interfaces/Workout/Workout';

@Injectable()
export class HomeService {

    private groupUrl = '/api/group';
    private fluxUrl = '/api/flux';
    private swimmerUrl = '/api/swimmer';

    constructor(private http: HttpClient) { }

    getCoachGroups(): Promise<Group[]> {
        return this.http
            .get(this.groupUrl)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

    getGroupGoals(id: string): Promise<CompiledGoals[]> {
        return this.http
            .get(`${this.groupUrl}/${id}/goals`)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

    getGroupPresence(id: string): Promise<Presence[]> {
        return this.http
            .get(`${this.groupUrl}/${id}/presence`)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

    getToday(idGroup: string): Promise<Workout[]> {
        return this.http
            .get(`${this.fluxUrl}/today/${idGroup}`)
            .toPromise()
            .then((response: any) => response)
            .catch(serviceHandler);
    }

    getSwimmerGroup(): Promise<Group> {
        return this.http
            .get(`${this.swimmerUrl}/group`)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

}
