import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { User } from '@Interfaces/User';
import { CoreService } from './Core/core.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

    public user: any;

    constructor(
        private location: Location,
        private translate: TranslateService,
        private core: CoreService
    ) { }

    enableFullStory() {
        (window as any).FS.identify(this.user._id, {
            displayName: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.email,
            role: this.user.role
        });
    }

    ngOnInit() {
        console.clear();
        console.log(`Padlie is running in ${this.core.env} mode`);
        const browserLang = this.translate.getBrowserLang();
        this.translate.setDefaultLang(browserLang || 'en');
        this.core.User$.subscribe((newUser: User) => {
            this.user = newUser;
            if (newUser.defaultLang) this.translate.use(newUser.defaultLang);
            const func_ptr = {
                swimmer: () => this.core.initForSwimmers(),
                coach: () => this.core.initForCoaches()
            }[this.user.role]();
            if (this.core.env === 'production') this.enableFullStory();
        });
    }

    hideRoutes() {
        const urlSlice = this.location.path().substring(1, 5);
        return urlSlice === 'auth';
    }

}
