import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { CoreService } from './Core/core.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private coreService: CoreService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // console.log(' - check Session - ');
        return this.coreService.checkSession()
            .then(() => Promise.resolve(true))
            .catch(err => {
                this.router.navigate(['/', 'auth', 'login'], { queryParams: { returnUrl: state.url } });
                return Promise.resolve(false);
            });
    }
}

@Injectable()
export class RedirectMembers implements CanActivate {

    constructor(private router: Router, private coreService: CoreService) { }

    canActivate(): Promise<boolean> {
        // console.log(' - check Session - ');
        return this.coreService.checkSession()
            .then(() => {
                this.router.navigate(['/']);
                return Promise.resolve(true);
            })
            .catch(err => Promise.resolve(true));
    }
}
