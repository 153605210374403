import { HttpErrorResponse } from '@angular/common/http';

export const serviceHandler = (err: HttpErrorResponse): Promise<any> => {
    const { status, message, error } = err;

    if (status === 504) {
        return Promise.reject({
            title: 'SERVER-ERROR-504.TITLE',
            messages: ['SERVER-ERROR-504.CONTENT'],
            status
        });
    } else if (status === 401) {
        return Promise.reject({
            title: 'SERVER-ERROR-401.TITLE',
            messages: ['SERVER-ERROR-401.CONTENT'],
            status
        });
    } else if (status === 500) {
        return Promise.reject({
            title: 'SERVER-ERROR-500.TITLE',
            messages: ['SERVER-ERROR-500.CONTENT'],
            status
        });
    }

    const currated = error && error.message
        ? [error.message]
        : error && error.errors
            ? error.errors
            : message;
    console.error('An error Occurred', currated);

    return Promise.reject({
        messages: currated,
        status,
        type: 'Error',
        data: error && error.data ? error.data : ''
    });
};
