/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "ngx-sharebuttons";
import * as i3 from "./swimmer-whitout-group.component";
var styles_SwimmerWhitoutGroupComponent = [];
var RenderType_SwimmerWhitoutGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwimmerWhitoutGroupComponent, data: {} });
export { RenderType_SwimmerWhitoutGroupComponent as RenderType_SwimmerWhitoutGroupComponent };
export function View_SwimmerWhitoutGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "ui segment very padded animated fadeInUp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 9, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "button", [["class", "ui facebook button"], ["shareButton", "facebook"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i2.ShareButtonDirective, [i2.ShareButtonsService, i0.Renderer2, i0.ChangeDetectorRef, i0.ElementRef, i2.ɵg], { createButton: [0, "createButton"], sbUrl: [1, "sbUrl"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "facebook icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "ui twitter button"], ["shareButton", "twitter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i2.ShareButtonDirective, [i2.ShareButtonsService, i0.Renderer2, i0.ChangeDetectorRef, i0.ElementRef, i2.ɵg], { createButton: [0, "createButton"], sbUrl: [1, "sbUrl"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["class", "twitter icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Twitter "])), (_l()(), i0.ɵeld(17, 0, null, null, 6, "h4", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "img", [["src", "/assets/img/Padlie-logo-minimal--default.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The Padlie Team "])), (_l()(), i0.ɵeld(21, 0, null, null, 2, "div", [["class", "sub header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["In hard work we trust"]))], function (_ck, _v) { var currVal_2 = "facebook"; var currVal_3 = "https://app.padlie.com"; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = "twitter"; var currVal_6 = "https://app.padlie.com"; _ck(_v, 14, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SWIMMER.EMPTY-GRP.TITLE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("SWIMMER.EMPTY-GRP.CONTENT")); _ck(_v, 5, 0, currVal_1); var currVal_4 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("BTN.SHARE")); _ck(_v, 11, 0, currVal_4); }); }
export function View_SwimmerWhitoutGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-swimmer-whitout-group", [], null, null, null, View_SwimmerWhitoutGroupComponent_0, RenderType_SwimmerWhitoutGroupComponent)), i0.ɵdid(1, 49152, null, 0, i3.SwimmerWhitoutGroupComponent, [], null, null)], null, null); }
var SwimmerWhitoutGroupComponentNgFactory = i0.ɵccf("app-swimmer-whitout-group", i3.SwimmerWhitoutGroupComponent, View_SwimmerWhitoutGroupComponent_Host_0, {}, {}, []);
export { SwimmerWhitoutGroupComponentNgFactory as SwimmerWhitoutGroupComponentNgFactory };
