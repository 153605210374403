<h4 class="title-separator">
    {{ 'HOME.FLUX.TITLE' | translate }}
    <i *ngIf="loading" class="fitted asterisk blue loading icon"></i>
</h4>

<!-- ALERT -->
<app-alert *ngIf="alert.type" (close)="closeErr()" [alert]="alert"
           [update]="alert.update"></app-alert>

<div *ngFor="let item of flux; let i = index">
    <a *ngIf="item.title" [routerLink]="['/', 'workout', item._id]">
        <div class="ui segment segment-flux animated fadeInUp">
            <span *ngIf="item.distance" class="ui blue ribbon label">
                {{ 'VOC.SWIMMING' | translate }}
            </span>
            <div class="ui label" *ngIf="item.showAbsence">
                <i class="remove icon red"></i>
                Absent<span *ngIf="swimmer._user.gender === 'female'">e</span>
            </div>
            <span class="pull-right thin-grey-text">
                <i *ngIf="item.secret && item.date > today"
                   class="lock icon"></i>
                <i *ngIf="item.secret && item.date < today"
                   class="unlock icon"></i>
                {{item.timeAgo}}
            </span>
            <h1 class="ui header">
                {{item.title}}
            </h1>
            <h4 *ngIf="item.note" class="ui header mt-none">
                <i class="quote left icon"></i>
                <div class="content">
                    <div class="sub header">
                        {{item.note}}
                    </div>
                </div>
            </h4>
            <div class="col-sm-12 spacing-y-sm">
                <shared-swimmers-list [swimmers]="item.swimmers">
                </shared-swimmers-list>
            </div>
            <div class="ui clearing divider invisible"></div>
            <div>
                <div class="ui label">
                    <i class="comment icon" style="width:14px"></i>
                    {{item.comments.length}}
                </div>
                <div class="ui label clear ">
                    <i class="flag checkered icon"></i>
                    {{item.distance/1000}} km
                </div>
                <div class="ui label pull-right">
                    <i class="lightning yellow icon"></i>
                    <b>{{item.pts}}</b>
                </div>
            </div>
        </div>
    </a>
    <div *ngIf="!item.title" class="ui segment animated fadeInUp">
        <span class="ui orange ribbon label">{{item.version}}</span>
        <span class="pull-right text-black thin">{{item.timeAgo}}</span>
        <span
              class="ui header">{{ 'HOME.FLUX.UPDATED-TITLE' | translate }}</span>
        <a class="ml-sm" href="http://bit.ly/2wbNxNJ" target="_blank">
            {{ 'HOME.FLUX.UPDATED-LINK' | translate }}
        </a>
    </div>
    <div class="row text-center spacing-y-sm">
        <i *ngIf="!flux[i].done && (flux[i+1] && flux[i+1].done)"
           class="checked calendar icon large grey spacing-y-md animated fadeInUp">
        </i>
    </div>
</div>
<app-welcome-message *ngIf="!loadMoreBtn && !loading && !swimmer">
</app-welcome-message>
<button *ngIf="loadMoreBtn" class="ui button fluid mb-md big"
        (click)="updateFlux(true)">{{ 'BTN.LOAD-MORE' | translate }}</button>