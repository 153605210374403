class AlertOptions {
    public compactMode?: boolean;
    public disableIcon?: boolean;
    public hideClose?: boolean;
    public content?: boolean;
    public autoScroll?= true;
    public container?: string;
}

export class Alert {

    public type: string;
    public messages: string[];
    public title: string;
    public options: AlertOptions = new AlertOptions();
    public update = 0;

    constructor(options?: AlertOptions) {
        if (options) {
            Object.assign(this.options, options);
        }
    }

    private assign(obj: any) {
        this.update += 1;
        if (!obj.messages) obj.messages = null;
        Object.assign(this, obj);
        return false;
    }

    setError(obj: any = {}): boolean {
        obj.type = 'Error';
        obj.title = obj.title || 'ALERT.DefaultError';
        if (!obj.status) {
            // TODO: Appeler Sentry
            console.error('[Set Error] - call Sentry', obj);
            obj.title = 'SERVER-ERROR-500.TITLE';
            obj.messages = ['SERVER-ERROR-500.CONTENT'];
        }
        return this.assign(obj);
    }

    setSuccess(obj: object = {}): boolean {
        this.type = 'Success';
        this.title = 'ALERT.DefaultSucess';
        return this.assign(obj);
    }

    setErrorMsg(messages: string[] = []): boolean {
        return this.setError({
            status: true,
            messages,
        });
    }

    setSuccessMsg(messages: string[] = []): boolean {
        return this.setSuccess({
            messages,
        });
    }

    reset() {
        this.title = null;
        this.type = null;
        this.messages = [];
    }

}
