import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, RedirectMembers } from './auth.guard';
import { HomeComponent } from './Home/home.component';

const routes: Routes = [
    { path: '', redirectTo: 'hello', pathMatch: 'full' },
    { path: 'hello', canActivate: [AuthGuard], component: HomeComponent },
    { path: 'auth', canActivate: [RedirectMembers], loadChildren: './Auth/auth.module#AuthModule' },
    { path: 'settings', canActivate: [AuthGuard], loadChildren: './Settings/settings.module#SettingsModule' },
    { path: 'swimmer', canActivate: [AuthGuard], loadChildren: './Swimmer/swimmer.module#SwimmerModule' },
    { path: 'workout', canActivate: [AuthGuard], loadChildren: './Swimming-Workout/sw-page/sw-page.module#SwPageModule' },
    { path: 'workout/edit', canActivate: [AuthGuard], loadChildren: './Swimming-Workout/sw-edit/sw-edit.module#SwEditModule' },
    { path: 'library', canActivate: [AuthGuard], loadChildren: './Swimming-Workout/library/library.module#LibraryModule' },
    { path: 'calendar', canActivate: [AuthGuard], loadChildren: './Calendar/calendar.module#CalendarModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


