/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./limiter.component";
var styles_LimiterComponent = [];
var RenderType_LimiterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LimiterComponent, data: {} });
export { RenderType_LimiterComponent as RenderType_LimiterComponent };
function View_LimiterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-sm-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "ui button mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toogleContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.src.text)); _ck(_v, 2, 0, currVal_0); }); }
export function View_LimiterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LimiterComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LimiterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-limiter", [], null, null, null, View_LimiterComponent_0, RenderType_LimiterComponent)), i0.ɵdid(1, 49152, null, 0, i3.LimiterComponent, [], null, null)], null, null); }
var LimiterComponentNgFactory = i0.ɵccf("app-limiter", i3.LimiterComponent, View_LimiterComponent_Host_0, { src: "src" }, {}, []);
export { LimiterComponentNgFactory as LimiterComponentNgFactory };
