import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Group } from '@App/+Interfaces/Group';
import { User } from '@App/+Interfaces/User';
import { serviceHandler } from '@Helpers/handler';

@Injectable()
export class CoreService {

    public user: User;
    public groupActive: Group;
    public groups: Group[];
    public groupIndex = -1;
    public env = 'development';

    private userSource = new Subject<User>();
    private GroupActiveSource = new Subject<Group>();
    private GroupsSource = new Subject<Group[]>();

    private groupUrl = '/api/group';
    private swimmerUrl = '/api/swimmer';

    User$ = this.userSource.asObservable();
    Groups$ = this.GroupsSource.asObservable();
    GroupActive$ = this.GroupActiveSource.asObservable();

    constructor(private http: HttpClient) {
        this.env = {
            'localhost': 'development',
            'warmup.padlie.com': 'staging',
            'app.padlie.com': 'production'
        }[window.location.hostname];
    }

    changeProfilePicture(path: string) {
        this.user.profile_picture = path;
        this.userSource.next(this.user);
    }

    changeUserName(firstName: string, lastName: string) {
        this.user.firstName = firstName;
        this.user.lastName = lastName;
        this.userSource.next(this.user);
    }

    checkSession(): Promise<User> {
        return this.http
            .get('/api/user')
            .toPromise()
            .then((response: any) => {
                const toEmit = !this.user;
                this.user = response;
                if (toEmit) this.userSource.next(this.user);
                return this.user;
            })
            .catch(serviceHandler);
    }

    getCoachGroups(): Promise<Group[]> {
        return this.http
            .get(this.groupUrl)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

    getSwimmerGroup(): Promise<Group> {
        return this.http
            .get(`${this.swimmerUrl}/group`)
            .toPromise()
            .then(response => response)
            .catch(serviceHandler);
    }

    logout(): Promise<any> {
        this.user = null;
        return this.http
            .get('/api/auth/logout')
            .toPromise()
            .then(() => {
                this.user = null;
                this.groupActive = null;
                this.groups = null;
                this.groupIndex = -1;
            })
            .catch(serviceHandler);
    }

    initForSwimmers(): void {
        if (!this.user.swimmer._group) return;
        this.getSwimmerGroup()
            .then((group: Group) => {
                this.groupActive = group;
                this.GroupActiveSource.next(this.groupActive);
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    initForCoaches(): void {
        this.getCoachGroups()
            .then((groups: Group[]) => {
                this.groups = groups;
                if (groups.length || !this.groupActive) {
                    this.groupIndex = 0;
                }
                this.groupActive = groups[this.groupIndex];
                this.GroupActiveSource.next(this.groupActive);
                this.GroupsSource.next(this.groups);
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

}
