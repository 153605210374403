import { Component, Input, OnInit } from '@angular/core';
import { sortSwimmers } from '@Helpers/sort';

@Component({
    selector: 'shared-swimmers-list',
    templateUrl: './swimmers-list.component.html'
})
export class SwimmersListComponent implements OnInit {

    public countPhoto = 0;
    public others = 0;
    public limit = {
        max: 7,
        min: 3
    };

    @Input() swimmers: any;

    constructor() { }

    ngOnInit() {
        this.swimmers.forEach(swimmer => {
            this.countPhoto += swimmer.swimmerID._user.profile_picture ? 1 : 0;
        });
        this.swimmers.sort((a, b) => sortSwimmers(a.swimmerID, b.swimmerID));
        this.others = this.countPhoto >= this.limit.max
            ? this.swimmers.length - this.limit.max
            : this.swimmers.length - this.limit.min;
    }

}
