import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '@App/Core/core.service';
import { User } from '@Interfaces/User';

@Component({
    selector: 'app-headerbar',
    templateUrl: './headerbar.component.html'
})
export class HeaderbarComponent implements OnInit {

    public user: User;
    public buttonName: string = null;
    public img: string;

    constructor(
        private router: Router,
        private core: CoreService,
        private el: ElementRef
    ) {
        core.User$.subscribe((newUser: User) => {
            this.img = newUser.profile_picture;
            this.buttonName = this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : this.user.email;
        });
    }

    onLinkClik() {
        this.el.nativeElement.querySelector('.navbar-toggle').click();
    }

    ngOnInit(): void {
        this.user = this.core.user;
        this.buttonName = this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : this.user.email;
    }

    logout() {
        this.core.logout()
            .then(() => {
                this.router.navigate(['/', 'auth', 'login']);
            });
    }

}
