import { FormControl } from '@angular/forms';

export const clone = (data: object) => {
    return JSON.parse(JSON.stringify(data));
};

export const toogle = (vartotoogle: boolean): boolean => {
    vartotoogle = !vartotoogle;
    return vartotoogle;
};

export const isNumber = (value: any): boolean => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

export const isNumberForm = (control: FormControl): any => {
    return !control.value || isNumber(control.value) ? null : { isNumber: false };
};

export const randomNumber = (min = 1, max = 200): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};

export const numbArr = (start: number, end: number): number[] => {
    const prepare: number[] = [];

    for (let i = start; i <= end; i++) {
        prepare.push(i);
    }
    return prepare;
};
