import { Component, Input } from '@angular/core';
import { Limiter } from '@Interfaces/Limiter';

@Component({
    selector: 'app-limiter',
    templateUrl: './limiter.component.html'
})
export class LimiterComponent {

    @Input() src: Limiter;

    constructor() { }

    toogleContent() {
        this.src.tooggle();
    }
}
