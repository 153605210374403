import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { CoreService } from '@App/Core/core.service';
import { HomeService } from '@App/Home/home.service';
import { Alert } from '@Interfaces/Alert';
import { Group } from '@Interfaces/Group';
import { Limiter } from '@Interfaces/Limiter';
import { Workout } from '@Interfaces/Workout/Workout';

@Component({
    selector: 'app-today',
    templateUrl: './today.component.html'
})
export class TodayComponent implements OnChanges {

    public alert: Alert = new Alert({ disableIcon: true });
    public loading = false;
    public ativities: Workout[] = [];
    public limiter: Limiter;
    public isCoach: boolean;

    @Input() group: Group;

    constructor(
        private homeService: HomeService,
        private coreService: CoreService
    ) {
        this.isCoach = coreService.user.role === 'coach';
    }

    updateToday(): void {
        this.loading = true;
        this.limiter = new Limiter(2);
        this.homeService
            .getToday(this.group._id)
            .then((ativities: Workout[]) => {
                this.loading = false;
                this.limiter.max = ativities.length;
                this.ativities = ativities;
            })
            .catch(err => {
                this.loading = false;
                this.alert.setError(err);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.group) {
            this.group = changes.group.currentValue;
            this.updateToday();
        }
    }

    closeErr() { this.alert.reset(); }

}
