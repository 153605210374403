<div class="ui segment very padded animated fadeInUp mb-lg">
    <span class="pull-right text-black thin">{{ welcomeDate }}</span>
    <h1 class="ui header">{{ 'HOME.WELCOME.TITLE' | translate }}</h1>
    <p [innerHTML]="'HOME.WELCOME.INTRO-TEXT-01' | translate"></p>
    <p [innerHTML]="'HOME.WELCOME.INTRO-TEXT-02' | translate"></p>
    <p [innerHTML]="'HOME.WELCOME.INTRO-TEXT-03' | translate"></p>
    <ul *ngIf="user.coach">
        <li>
            {{ 'HOME.WELCOME.LINK-01' | translate }}
            <a class="ui label tiny" [routerLink]="['/settings/informations']">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
        <li>
            {{ 'HOME.WELCOME.LINK-02' | translate }}
            <a class="ui label tiny" [routerLink]="['/settings/informations']"
               [queryParams]="{ group: 0}">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
        <li>
            {{ 'HOME.WELCOME.LINK-03' | translate }}
            <a class="ui label tiny" [routerLink]="['/workout/edit/new']">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
        <li>
            {{ 'HOME.WELCOME.LINK-04' | translate }}
            <a class="ui label tiny" [routerLink]="['/library/list']">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
    </ul>
    <ul *ngIf="user.swimmer">
        <li>
            {{ 'HOME.WELCOME.LINK-05' | translate }}
            <a class="ui label tiny" [routerLink]="['/settings/informations']">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
        <li>
            {{ 'HOME.WELCOME.LINK-06' | translate }}
            <a class="ui label tiny"
               [routerLink]="['/swimmer', user.swimmer._id]">
                <i class="arrow right icon mr-none"></i>
            </a>
        </li>
    </ul>
    <h4 class="ui header">
        <img src="assets/img/Padlie-logo-minimal--default.png">
        <div class="content">
            The Padlie Team
            <div class="sub header">
                <i>In hard work we trust</i>
            </div>
        </div>
    </h4>
</div>