
export class Limiter {
    public limit = 1;

    public show = false;
    private text = 'BTN.SEE-MORE';
    private min?: number;

    public _max: number;
    get max(): number { return this._max; }
    set max(newMax: number) {
        this._max = newMax;
        this.show = this._max > this.min;
    }

    constructor(limit: number, options?: any) {
        if (options) {
            Object.assign(this, options);
            if (options.max) this.show = options.max > options.min;
        }
        this.limit = limit;
        this.min = this.limit;
    }

    public tooggle(): void {
        if (this.limit === this.min) {
            this.limit = this._max;
            this.text = 'BTN.SEE-LESS';
        } else {
            this.limit = this.min;
            this.text = 'BTN.SEE-MORE';
        }
    }
}
