import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';

import { HomeModule } from './Home/home.module';
import { SharedModule } from './Shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard, RedirectMembers } from './auth.guard';
import { CoreModule } from './Core/core.module';

import { HeaderbarComponent } from './Headerbar/headerbar.component';

import { CustomErrorHandler } from '@Helpers/ErrorHandler';

import * as Sentry from '@sentry/browser';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

Sentry.init({
    dsn: 'https://b9f444c465d140dfaecea5d4c20b0bd8@sentry.io/1225130',
    maxBreadcrumbs: 30
});

@NgModule({
    declarations: [
        AppComponent,
        HeaderbarComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        HttpClientModule,
        HomeModule,
        ChartsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        })
    ],
    providers: [
        AuthGuard, RedirectMembers,
        Title,
        { provide: ErrorHandler, useClass: CustomErrorHandler }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
