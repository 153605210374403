import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@App/Shared/shared.module';

import { HomeService } from './home.service';

import { GoalsComponent } from './goals/goals.component';
import { HomeComponent } from './home.component';
import { PresenceComponent } from './presence/presence.component';
import { SwimmersListComponent } from './swimmers-list/swimmers-list.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { TodayComponent } from './today/today.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        HomeComponent,
        SwitcherComponent,
        SwimmersListComponent,
        GoalsComponent,
        PresenceComponent,
        TodayComponent
    ],
    providers: [HomeService]
})
export class HomeModule { }
