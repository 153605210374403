import * as jQuery from '@jQuery';

export const smoothScroll = (target: string, container: string = 'html', duration: number = 800) => {
    setTimeout(() => {
        const isDefault = container === 'html';
        const targetElem = jQuery(target);
        if (!targetElem) return;
        const containerElem = jQuery(container);
        if (!containerElem) return;
        const targetOffset = targetElem.offset();
        const containerOffset = containerElem.offset();
        if (!targetOffset || !containerOffset) return;
        if (!targetOffset.top || !containerOffset.top) return;
        const posTargetRelative = targetOffset.top - containerOffset.top - 10;
        const scrolled = containerElem.scrollTop();
        containerElem.animate({
            scrollTop: posTargetRelative + (isDefault ? 0 : scrolled)
        }, duration);
    }, 100);
};
export const smoothScrollBottomElem = (elem: string, duration: number = 1200) => {
    const getElem = jQuery(elem)[0]
    if (getElem) {
        const targetPos = getElem.scrollHeight;
        jQuery(elem).animate({
            scrollTop: targetPos
        }, duration);
    }

};
