/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./switcher.component";
var styles_SwitcherComponent = [];
var RenderType_SwitcherComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwitcherComponent, data: {} });
export { RenderType_SwitcherComponent as RenderType_SwitcherComponent };
function View_SwitcherComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeGroup(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "a", [["class", "ui empty circular label"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, (_v.context.index === _co.index)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.color; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_3); }); }
function View_SwitcherComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui fluid vertical menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groups; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SwitcherComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["class", "ui button inner-sp-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeGroup(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "a", [["class", "ui empty circular label"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ui button inner-sp-xs"; var currVal_1 = _ck(_v, 3, 0, (_v.context.index === _co.index)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.color; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_3); }); }
function View_SwitcherComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "three ui basic buttons large"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SwitcherComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-sm-12 mb-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "title-separator"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwitcherComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.groups.length > 2); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.groups.length <= 2); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("HOME.SWITCH")); _ck(_v, 2, 0, currVal_0); }); }
export function View_SwitcherComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-switcher", [], null, null, null, View_SwitcherComponent_0, RenderType_SwitcherComponent)), i0.ɵdid(1, 49152, null, 0, i3.SwitcherComponent, [], null, null)], null, null); }
var SwitcherComponentNgFactory = i0.ɵccf("app-switcher", i3.SwitcherComponent, View_SwitcherComponent_Host_0, { groups: "groups", index: "index" }, { newGroupIndex: "newGroupIndex" }, []);
export { SwitcherComponentNgFactory as SwitcherComponentNgFactory };
