/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/alert/alert.component.ngfactory";
import * as i2 from "../../Shared/alert/alert.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../Shared/limiter/limiter.component.ngfactory";
import * as i6 from "../../Shared/limiter/limiter.component";
import * as i7 from "./goals.component";
import * as i8 from "../home.service";
var styles_GoalsComponent = [];
var RenderType_GoalsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoalsComponent, data: {} });
export { RenderType_GoalsComponent as RenderType_GoalsComponent };
function View_GoalsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fitted asterisk blue loading icon"]], null, null, null, null, null))], null, null); }
function View_GoalsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeErr() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 573440, null, 0, i2.AlertComponent, [i3.TranslateService], { alert: [0, "alert"], update: [1, "update"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; var currVal_1 = _co.alert.update; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_GoalsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h4", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("HOME.GOALS.EMPTY")); _ck(_v, 1, 0, currVal_0); }); }
function View_GoalsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h3", [["class", "mt-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "ui label pull-left small"], ["style", "margin: 0px 10px 0 0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["x", ""])), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_GoalsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_6)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index < _co.limiter.limit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GoalsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_5)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-limiter", [], null, null, null, i5.View_LimiterComponent_0, i5.RenderType_LimiterComponent)), i0.ɵdid(4, 49152, null, 0, i6.LimiterComponent, [], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.goals; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.limiter; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GoalsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "col-sm-12 mb-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h4", [["class", "title-separator"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_3)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoalsComponent_4)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.alert.type; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.goals.length; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.goals.length; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("HOME.GOALS.TITLE")); _ck(_v, 2, 0, currVal_0); }); }
export function View_GoalsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-goals", [], null, null, null, View_GoalsComponent_0, RenderType_GoalsComponent)), i0.ɵdid(1, 573440, null, 0, i7.GoalsComponent, [i8.HomeService], null, null)], null, null); }
var GoalsComponentNgFactory = i0.ɵccf("app-goals", i7.GoalsComponent, View_GoalsComponent_Host_0, { group: "group" }, {}, []);
export { GoalsComponentNgFactory as GoalsComponentNgFactory };
