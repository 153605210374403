<img class="{{baseClass}} {{imgClass}}" [ngClass]="{loaded: loaded}"
     (load)="loadedAction()" *ngIf="imgDisplay" [src]="imgDisplay">

<img class="{{baseClass}} {{imgClass}}" [ngClass]="{loaded: loaded}"
     (load)="loadedAction()" *ngIf="!imgDisplay && user.gender === 'male'"
     src="assets/img/male.png">

<img class="{{baseClass}} {{imgClass}}" [ngClass]="{loaded: loaded}"
     (load)="loadedAction()" *ngIf="!imgDisplay && user.gender === 'female'"
     src="assets/img/female.png">

<img class="{{baseClass}} {{imgClass}}" [ngClass]="{loaded: loaded}"
     (load)="loadedAction()" *ngIf="!imgDisplay && !user.gender"
     src="assets/img/every-user.jpg">