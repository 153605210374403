import { Swimmer } from '@Interfaces/Swimmer';

export const sortSwimmers = (a: Swimmer, b: Swimmer): number => {
    const userA = a._user;
    const userB = b._user;

    if (userA.profile_picture && userB.profile_picture) {
        return userA.last_connection <= userB.last_connection ? 1 : -1;
    }
    if (!userA.profile_picture && !userB.profile_picture) {
        return userA.last_connection <= userB.last_connection ? 1 : -1;
    }
    if (userA.profile_picture && !userB.profile_picture) return -1;
    if (!userA.profile_picture && userB.profile_picture) return 1;
};
