/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/avatar/avatar.component.ngfactory";
import * as i2 from "../../Shared/avatar/avatar.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../Shared/limiter/limiter.component.ngfactory";
import * as i7 from "../../Shared/limiter/limiter.component";
import * as i8 from "./swimmers-list.component";
var styles_SwimmersListComponent = [];
var RenderType_SwimmersListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwimmersListComponent, data: {} });
export { RenderType_SwimmersListComponent as RenderType_SwimmersListComponent };
function View_SwimmersListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "wait icon"]], null, null, null, null, null))], null, null); }
function View_SwimmersListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "check icon green"]], null, null, null, null, null))], null, null); }
function View_SwimmersListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirect(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-avatar", [["imgClass", "mini rounded margin-right"]], null, null, null, i1.View_AvatarComponent_0, i1.RenderType_AvatarComponent)), i0.ɵdid(2, 573440, null, 0, i2.AvatarComponent, [], { user: [0, "user"], imgClass: [1, "imgClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "content inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_3)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_4)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(9, null, [" ", " ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit._user; var currVal_1 = "mini rounded margin-right"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_v.parent.context.$implicit.joined; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.joined; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit._user.firstName; var currVal_5 = _v.parent.context.$implicit._user.lastName; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_SwimmersListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index < _co.limiter.limit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SwimmersListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [["class", "ui button fluid"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(3, { group: 0 }), i0.ɵpad(4, 3), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.index); var currVal_3 = _ck(_v, 4, 0, "/", "settings", "informations"); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("HOME.SWIMMERS.ADD-SWIMMER")); _ck(_v, 5, 0, currVal_4); }); }
export function View_SwimmersListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "col-sm-12 mb-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h4", [["class", "title-separator"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpod(3, { value: 0 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "ui middle aligned selection list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_1)), i0.ɵdid(7, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SwimmersListComponent_5)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-limiter", [], null, null, null, i6.View_LimiterComponent_0, i6.RenderType_LimiterComponent)), i0.ɵdid(11, 49152, null, 0, i7.LimiterComponent, [], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.group.swimmers; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.group.swimmers && !_co.group.swimmers.length); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.limiter; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform("HOME.SWIMMERS.TITLE", _ck(_v, 3, 0, _co.group.swimmers.length))); _ck(_v, 2, 0, currVal_0); }); }
export function View_SwimmersListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-swimmers-list", [], null, null, null, View_SwimmersListComponent_0, RenderType_SwimmersListComponent)), i0.ɵdid(1, 573440, null, 0, i8.SwimmersListComponent, [i4.Router], null, null)], null, null); }
var SwimmersListComponentNgFactory = i0.ɵccf("app-swimmers-list", i8.SwimmersListComponent, View_SwimmersListComponent_Host_0, { group: "group", index: "index" }, {}, []);
export { SwimmersListComponentNgFactory as SwimmersListComponentNgFactory };
